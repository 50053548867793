@import "../../../mixins";
@import "../../../vars";

.register {
  background: #202020;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__container {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 22px 30px 70px;
    display: flex;
    flex-direction: column;
    width: calc(396px + 60px);
  }

  &__menu {
    display: flex;
  }

  &__info {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 0 0 44px;
    margin-top: 40px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    color: #FFF;
  }

  &__form {
    position: relative;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 8px;
  }

  &__footer {
    margin: 16px auto 70px;
    height: 17px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    color: #8B8B8B;
  }

  &__link-blue {
    color: #4285F4;

    &:focus,
    &:active,
    &:visited {
      color: #4285F4;
    }
  }

  &__error-message {
    height: 12px;
    margin: 0 auto 20px;
  }

}

@media screen and (max-width: 480px) {
  .register {
    height: 100vh;

    &__container {
      width: calc(260px + 60px);
      align-items: center;
      justify-content: space-between;
      padding: 56px 30px 30px;
      height: 100%;
    }

    &__info {
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }

    &__title {
      text-align: center;
    }

    &__form {
      height: 100%;
    }

    &__footer {
      font-size: 12px;
    }
  }
}
