.popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  z-index: 5;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 300px;
    background-color: white;
    margin: 0 auto;
    padding: 0;
    border-radius: 10px;
    z-index: 7;
  }

  &__button-close {
    background: white;
    box-sizing: border-box;
    border: none;
    border-radius: 10px;
    width: 44px;
    height: 44px;
    align-self: flex-end;
  }

  &__icon-close {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}