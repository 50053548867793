.habr {
    height: calc(100vh - 74px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  
    &__section {
      background: #202020;
    }
}