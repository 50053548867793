@import "../../../mixins";
@import "../../../vars";

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  min-height: calc(100vh - 74px);
  background: #202020;

  &__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 410px;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 0 0 107px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    color: #FFF;
    text-align: center;
  }

  &__form {
    position: relative;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 171px;
  }


  &__footer {
    margin: 16px auto 0;
    height: 17px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    color: #8B8B8B;
  }

  &__link-red {
    color: #EE3465;

    &:focus,
    &:active,
    &:visited {
      color: #EE3465;
      ;
    }
  }

  &__error-message {
    height: 12px;
    margin: 0 auto 20px;
  }
}


@media screen and (max-width: 480px) {
  .profile {
    height: calc(100vh - 74px);

    &__container {
      padding: 70px 0;
      width: 260px;
      justify-content: space-between;
      height: 100%;
    }

    &__info {
      justify-content: space-between;
      height: 100%;
    }

    &__header {
      padding: 0 0 64px;
    }

    &__form {
      height: 100%;
    }

    &__inputs {
      margin-bottom: 0;
    }

    &__footer {
      font-size: 12px;
    }
  }
}
