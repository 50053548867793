.error {
  box-sizing: border-box;
  visibility: hidden;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: #EE3465;
  min-height: 12px;

  &_green {
    color: #0aa756;
  }

  &_visible {
    visibility: visible;
  }
}
