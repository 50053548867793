@import "../../../mixins";
@import "../../../vars";

.header {
  &_background-light {
    background: #073042;
  }

  &_background-dark {
    background: #202020;
  }

  &__container {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--max-width-large);
    min-height: var(--min-height-header-ladge);
  }

  &__navigation {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;

    &_right {
      column-gap: 30px;
    }

    &_middle {
      margin-left: 72px;
      column-gap: 16px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: #FFFFFF;
    border-bottom: 1px solid #DADADA;
  }

  &__link {
    @include padding-margin-null-auto();
    font-weight: 500;
    font-size: 12px;


    &_rect {
      line-height: 32px;
      width: 76px;
      height: 32px;
      background: var(--color-green);
      color: #000;
      border-radius: 3px;
      text-align: center;
      vertical-align: middle;
    }

    &_normal {
      line-height: 1.3;
      color: var(--color-light);
      border: none;
    }
  }


  &__link-videos {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.4;
    color: #FFF;

    &:focus,
    &:active,
    &:visited {
      color: #FFF;
    }
  }

  &__link-account {
    text-align: center;
    color: #FFF;
    background: #313131;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    width: 100px;
    height: 32px;
  }
}

@media screen and (max-width: 768px) {
  .header {

    // &__container {
    //   padding: 0px 30px;
    // }

    &__nav-btn {
      background: #202020;
      border: none;

      &_main {
        background: #073042;
      }
    }

    &__nav-btn-img {
      height: 40px;
      width: 40px;
    }
  }
}

@media screen and (max-width: 480px) {
  .header {

    // &__container {
    //   padding: 0px 14px;
    // }

  //   &__navigation {
  //     &_right {
  //       column-gap: 14px;
  //     }
  //   }

  //   &__link {
  //     font-size: 10px;

  //     &_rect {
  //       line-height: 26px;
  //       width: 54px;
  //       height: 26px;
  //     }

  //     &_normal {
  //       line-height: 1.6;
  //     }
  //   }
  }
}