@import "../../../mixins";
@import "../../../vars";

.cards {
  margin: 0 auto;
  padding: 20px 0 0;
  width: 100%;

  &__list {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
  }
}
