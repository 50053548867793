@import "../../../mixins";
@import "../../../vars";

.videos {
  height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__section {
    background: #202020;
  }

  &__container {
    margin: 0 auto;
    padding: 5px 30px 80px;
    max-width: var(--max-width-large);
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  &__unsuccess-search {
    margin: 25px auto;
    color: #FFF;
  }

  &__button-open-popup {
    width: 100px;
    height: 32px;
    margin-bottom: 15px;
    padding: 0 15px;
    border: none;
    border-radius: 20px;
    background: #313131;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
  }

  &__more-button {
    margin-top: 60px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
    color: var(--color-light);
    border: none;
    max-width: var(--max-width-large);
    width: 100%;
    height: 36px;
    background: #2F2F2F;
    border-radius: 6px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .videos {
    &__more-button {
      margin-top: 80px;
    }
  }
}

@media screen and (max-width: 480px) {
  .videos {
    // &__container {
    //   padding: 35px 10px 80px;
    // }

    &__more-button {
      margin: 70px auto 0;
      width: 300px;
    }
  }
}