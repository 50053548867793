.popup {
  &__title {
    margin: 0 auto;
  }

  &__warning {
    padding-top: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.2;
  }

  &__form {
    width: 100%;
    max-width: 240px;
  }

  &__validation {
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
    max-height: 12px;
  }
}