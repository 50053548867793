@import "../../../mixins";
@import "../../../vars";

.form {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__submit {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
    width: 100%;

    &:hover {
      opacity: 0.6;
    }

    &_login {
      margin-top: 100px;
    }

    &_profile {
      font-weight: 400;
      font-size: 13px;
      line-height: 1.2;
      text-align: center;
      color: #FFFFFF;
      background: none;
      border: none;
    }

    &_blue {
      background: #4285F4;
      border-radius: 3px;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      text-align: center;
      color: #FFFFFF;
      height: 45px;
    }

    &_normal {
      font-weight: 400;
      font-size: 13px;
      line-height: 1.2;
      text-align: center;
      color: #FFFFFF;
      height: 16px;
    }

    &_disabled {
      background: rgb(170, 170, 170);
      color: rgb(40, 40, 40);
      cursor: auto;
      transition: none;

      &:hover {
        opacity: 1;
      }

    }
  }
}

@media screen and (max-width: 480px) {
  .form {
    justify-content: space-between;

    &__submit {
      &_profile {
        font-size: 12px;
      }

      &_blue {
        font-size: 12px;
      }
    }
  }
}