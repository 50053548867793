@import "../../mixins";
@import "../../vars";

.page-error {
  margin: 0 auto;
  background: #202020;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100vh;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title {
    margin: 0 0 5px;
    font-weight: 400;
    font-size: 140px;
    line-height: 1.2;
    text-align: center;
    color: #FFFFFF;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: #FFFFFF;
  }

  &__button {
    margin: 0 auto;
    border: none;
    color: #4285F4;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    background: #202020;
  }
}

@media screen and (max-width: 480px) {
  .page-error {
    &__title {
      font-size: 80px;
      margin: 0 0 10px;
    }

    &__button {
      font-size: 12px;
    }
  }
}