.tooltip {
  z-index: 2;
  cursor: pointer;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: .8;
  }

  &__container {
    box-sizing: border-box;
    padding: 10px;
    position: absolute;
    top: calc(-100% - 100px);
    transform: translateX(-50%);
    color: white;
    background-color: rgb(28, 28, 28);
    border-radius: 5px;
    max-width: 430px;
    min-width: 430px;
  }

  &__text {
    padding: 0;
    font-size: 14px;
    line-height: 1.2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

@media screen and (max-width: 700px) {
  .tooltip {
    &__container {
      max-width: 310px;
      min-width: 310px;
      top: calc(-100% - 80px);
      transform: translateX(-5%);
    }
  }
}