.side-bar {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.6s, opacity 0.3s ease-in-out;

  &_visible {
    visibility: visible;
    opacity: 1;
    z-index: 5;
  }

  &__button-close {
    background: rgba(32, 32, 32, 1);
    box-sizing: border-box;
    border: none;
    position: fixed;
    top: 15px;
    right: 20px;
    width: 44px;
    height: 44px;
  }

  &__icon-close {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__menu {
    min-width: 520px;
    min-height: 100vh;
    background: rgba(32, 32, 32, 1);
    box-sizing: border-box;
    padding: 159px 22px 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__group-menu {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 28px;
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    color: #FFF;
    border-bottom: 1px solid #DADADA;
  }

  &__link {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    color: #FFF;

    &:focus,
    &:active,
    &:visited {
      color: #FFF;
    }
  }
}

@media screen and (max-width: 520px) {
  .side-bar {
    min-width: 320px;

    // &__button-close {
    //   right: 14px;
    // }

    &__menu {
      min-width: 320px;
      width: 100%;
    }
  }
}
