.footer {
  background: #202020;

  &__section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: var(--max-width-large);
    padding: 79px 70px 20px;
    align-items: stretch;
  }

  &__group-header {
    border-bottom: 1px solid #424242;
    min-height: 36px;
  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2;
    text-align: center;
    color: #8B8B8B;
  }

  &__link {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--color-light);
    border: none;
    font-weight: normal;
    margin: 0;
  }

  &__group-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__year {
    font-weight: normal;
    font-size: 13px;
    line-height: 1.2;
    color: #FFF;
  }

  &__list-links {
    display: flex;
    column-gap: 20px;
    list-style-type: none;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    &__section {
      padding: 79px 30px 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  .footer {
    &__section {
      padding: 79px 10px 20px;
    }

    &__group-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      row-gap: 30px;
    }

    &__year {
      font-size: 12px;
      color: #8B8B8B;
    }

    &__list-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 12px;
      order: -1;
    }
  }
}