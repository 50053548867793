@import "../../../mixins";
@import "../../../vars";

.search-form {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 0 30px;
  max-width: var(--max-width-large);
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  border-bottom: 1px solid rgba(170, 170, 170, 0.2);

  &__container {
    box-sizing: border-box;
    padding: 10px;
    border: 1.5px solid rgba(170, 170, 170, 0.2);
    border-radius: 8px;
    width: 100%;
    min-height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
  }

  &__input {
    box-sizing: border-box;
    padding-left: 15px;
    background: #202020;
    border: none;
    width: 100%;
    min-height: 52px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.1;
    color: var(--color-light);
    box-shadow: inset 0 0 0 1px rgba(32, 32, 32, 0), inset 0 0 0 100px rgba(32, 32, 32, 1);
    overflow: scroll;

    &:focus {
      outline: auto;
    }
  }

  &__search-button {
    width: 100px;
    height: 52px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    background: #4285F4;
    border-radius: 5px;
    color: var(--color-light);
    border: none;
  }

  &__switch-text {
    color: var(--color-light);
    font-weight: 400;
    font-size: 13px;
    line-height: 1.4;
  }

}

@media screen and (max-width: 480px) {
  .search-form {
    // padding: 0 4px 30px;

    &__container {
      box-sizing: border-box;
      padding: 6px;
    }

    &__input {
      font-size: 16px;
      line-height: 1;
    }

    &__search-button {
      width: 80px;
      height: 44px;
    }

    &__switch-text {
      font-size: 11px;
      line-height: 1.6;
    }
  }
}
