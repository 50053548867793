@import "../../../mixins";
@import "../../../vars";

.promo {
  background: #073042;

  &__section {
    margin: 0 auto;
    box-sizing: border-box;
    max-width: var(--max-width-large);
    width: 100%;
    padding: 0px 70px 40px;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    row-gap: 102px;
  }

  &__name-project {
    box-sizing: border-box;
    margin: 50px 0 0;
    max-width: 624px;
    width: 100%;
    max-height: 104px;
    font-weight: normal;
    font-size: 40px;
    line-height: 1.3;
    letter-spacing: -0.04em;
    color: #FFF;
  }

  &__container {
    align-self: flex-end;
  }

  &__abstraction {
    max-width: 722px;
    width: 100%;
    min-height: 244px;
    opacity: 0.7;
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .promo {
    &__section {
      padding: 0px 30px 70px;
      row-gap: 140px;
    }

    &__name-project {
      margin: 300px auto 0;
      text-align: center;
    }

    &__container {
      align-self: center;
    }

    &__abstraction {
      margin: 140px auto 0;
      max-width: 680px;
      min-height: 230px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 480px) {
  .promo {
    &__section {
      padding: 0 0 40px;
      row-gap: 40px;
    }

    &__name-project {
      margin: 183px auto 0;
      font-size: 31px;
      padding: 0 10px;
    }

    &__container {
      padding: 0;
    }
  }
}
