@import "../../../mixins";
@import "../../../vars";

.about-me {
  background: #202020;

  &__section {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 110px 70px 61px;
    max-width: var(--max-width-large);
    display: flex;
    flex-direction: column;
  }

  &__header {
    border-bottom: 1px solid #DADADA;
    padding-bottom: 23px;
  }

  &__name {
    font-weight: normal;
    font-size: 22px;
    line-height: 1.2;
    color: #FFFFFF;
    letter-spacing: -0.04em;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__info {
    max-width: 660px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__my-name {
    margin-top: 66px;
    font-weight: 400;
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: -0.04em;
    color: #FFF;
  }

  &__my-position {
    margin-top: 18px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.1;
    color: #FFF;
    mix-blend-mode: normal;
  }

  &__my-hystory {
    margin-top: 26px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.6;
    color: #FFF;
    mix-blend-mode: normal;
  }

  &__link {
    @include padding-margin-null-auto();
    font-weight: 500;
    color: var(--color-light);
    font-size: 14px;

    &_big-normal {
      margin: 0;
      line-height: 1.2;
      border: none;
    }

    &_underlined {
      line-height: 1.6;
      border-bottom: 1px solid #FFF;
    }
  }

  &__my-photo {
    margin-top: 66px;
    width: 270px;
    height: 327px;
    border-radius: 10px;
    object-fit: cover;
  }
}


@media screen and (max-width: 1000px) {
  .about-me {

    &__info {
      max-width: 364px;
    }

    &__my-name {
      font-size: 40px;
      line-height: 1;
    }

    &__my-position {
      margin-top: 16px;
      font-size: 12px;
      line-height: 1.5;
    }

    &__my-hystory {
      margin-top: 20px;
      font-size: 12px;
      line-height: 1.5;
    }

    &__link {
      &_underlined {
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-me {
    &__section {
      padding: 90px 50px 51px;
    }

    &__my-photo {
      width: 255px;
      height: 307px;
    }
  }
}


@media screen and (max-width: 730px) {
  .about-me {
    &__container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    &__info {
      max-width: 730px;
    }

    &__my-name {
      margin-top: 40px;
    }

    &__my-position {
      margin-top: 20px;
    }

    &__my-photo {
      order: -1;
    }

    &__link {
      &_big-normal {
        margin-top: 40px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .about-me {
    &__section {
      padding: 70px 14px 30px;
    }

    &__header {
      padding-bottom: 28px;
    }

    &__name {
      font-size: 18px;
    }
  }
}
