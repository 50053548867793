@import "../../../mixins";
@import "../../../vars";

.project {
  margin: 0;
  padding-bottom: 9px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #424242;
  ;

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__link {
    font-size: 30px;
    line-height: 50px;
    letter-spacing: -0.04em;
    color: var(--color-light);
    border: none;
    font-weight: normal;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .project {
    &__link {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 730px) {
  .project {
    min-height: 28px;

    &__link {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
