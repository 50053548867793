@import "../../../mixins";
@import "../../../vars";

.switch {
  display: flex;
  column-gap: 13px;
  align-items: center;

  &__input {
    padding: 0;
    margin: 0;
    display: none;

    &:focus {
      outline: auto;
    }
  }

  &__img {
    cursor: pointer;
    width: 36px;
    height: 20px;
    transition: var(--transition);

    &:hover {
      opacity: var(--opacity);
    }

    &_disabled {
      cursor: auto;
      transition: none;

      &:hover {
        opacity: 1;
      }
    }
  }
}