@import "../../../mixins";
@import "../../../vars";

.button {
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    opacity: var(--opacity);
  }

}